import React, {useEffect, useState} from "react";
import useAxios from "axios-hooks";
import UserService from "../../services/user.service";
import {
    Autocomplete,
    Box, Button, Checkbox,
    Chip,
    Dialog, DialogActions,
    DialogContent,
    Divider,
    InputAdornment, ListItem, ListItemText,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {Check, Clear, GroupAdd, GroupRemove, Search} from "@mui/icons-material";
import CustomDialogTitle from "../../components/CustomDialogTitle";
import IconButton from "@mui/material/IconButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomConfirmDialog from "../../components/CustomConfirmDialog";
import {Alert} from "@mui/lab";

const MatchCompagnoCamera = ({exactMatch, user, refetch}) => {
    const [openDialog, setOpenDialog] = useState(false)
    const [openOptions, setOpenOptions] = useState(false)
    const [surname, setSurname] = useState(!exactMatch ? user?.cognomeCompagnoCamera : '')
    const [selectedRoomate, setSelectedRoomate] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [errorMessage, setError] = useState(null);

    const handleToggleConfirmDialog = () => {
        setOpenConfirmDialog(!openConfirmDialog)
    }

    const [{_, loading: saving, error: errorSaving}, saveMatch] = useAxios(
        {method: "POST"}, {useCache: false, manual: true}
    )

    const [{__, loading: deleting, error: errorDeleting}, deleteMatch] = useAxios(
        {method: "POST"}, {useCache: false, manual: true}
    )

    const [{data: users, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl({surname: surname}),
        {method: "GET", useCache: false, manual: true}
    )

    useEffect(() => {
        fetchUsers()
    }, [])

    useEffect(() => {
        if (users)
            setOpenOptions(true)
    }, [users])

    useEffect(() => {
        if (errorSaving)
            setError(errorSaving?.response?.data?.message || errorSaving.toString())
    }, [errorSaving])

    function handleToggleDialog(event) {
        event?.preventDefault()
        event?.stopPropagation()
        if (openDialog === true) {
            setOpenOptions(false)
            setSurname(!exactMatch ? user?.cognomeCompagnoCamera : '')
            setSelectedRoomate(null)
            setError(null)
        }
        setOpenDialog(!openDialog)
    }

    function handleAssignRoomate() {
        console.log("selectedRoomate:",selectedRoomate)
        saveMatch({
            url: `${UserService.userDataUrl(user.id)}/set-exact-match-room/${selectedRoomate.id}`,
        })
            .then((res) => {
                console.log("res:",res)
                refetch()
                handleToggleDialog()
            })
            .catch((err) => console.log("err:", err))
            .finally(() => handleToggleConfirmDialog())
    }

    function handleRemoveRoomate() {
        console.log("selectedRoomate:",selectedRoomate)
        deleteMatch({
            url: `${UserService.userDataUrl(user.id)}/delete-match-room/${exactMatch.compagnoCameraId}`,
        })
            .then((res) => {
                console.log("res:",res)
                refetch()
                handleToggleDialog()
            })
            .catch((err) => console.log("err:", err))
            .finally(() => handleToggleConfirmDialog())
    }

    return (<>
            <Chip
                icon={!!exactMatch ? <Check/> : <GroupAdd/>}
                label={!!exactMatch ? `MATCH: ${exactMatch?.nome} ${exactMatch?.cognome}` : `Assegna compagno`}
                size={'small'}
                color={!!exactMatch ? 'success' : 'warning'}
                onClick={handleToggleDialog}
            />
            <Dialog open={openDialog} onClose={handleToggleDialog} keepMounted={false} disableEscapeKeyDown>
                <CustomDialogTitle title={`Assegna o aggiorna compagno di camera`}
                                   handleCloseDialog={handleToggleDialog}/>
                <DialogContent>
                    <Stack>
                        <Typography fontSize={'h5'} fontWeight={'bold'}>{user.nome} {user.cognome}</Typography>
                        {
                            (!exactMatch) ?
                                <Stack>
                                    <Typography>
                                        ha inserito come suo compagno di
                                        camera <strong>{user?.nomeCompagnoCamera} {user?.cognomeCompagnoCamera}</strong>
                                    </Typography>
                                    <Stack mt={2} spacing={2}>
                                        <TextField
                                            variant={'standard'}
                                            id="outlined-controlled"
                                            label="Cerca il COGNOME del compagno in DB"
                                            value={surname}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setSurname(event.target.value);
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <Box>
                                                        <IconButton
                                                            onClick={fetchUsers}
                                                            edge="end"
                                                        >
                                                            <Search/>
                                                        </IconButton>
                                                        <IconButton
                                                            onClick={() => {
                                                                setSurname('')
                                                            }}
                                                            edge="end"
                                                        >
                                                            <Clear/>
                                                        </IconButton>
                                                    </Box>
                                                </InputAdornment>
                                            }}
                                        />
                                        <Divider/>
                                        {users && <Autocomplete
                                            fullWidth
                                            size={'small'}
                                            loading={loadingUsers}
                                            loadingText={'Attendere'}
                                            //groupBy={(option) => option.section}
                                            value={selectedRoomate}
                                            open={openOptions}
                                            onOpen={() => setOpenOptions(true)}
                                            onClose={() => setOpenOptions(false)}
                                            onChange={(event, newValue) => {
                                                setSelectedRoomate(newValue)
                                            }}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                           label={`Scegli l'utente da assegnare a ${user.nome} ${user.cognome}`}
                                                />}
                                            options={users || []}
                                            getOptionLabel={(option) => {
                                                return option.cognome + " " + option.nome;
                                            }}
                                            renderOption={(props, option, {selected}) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                                        checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                                        style={{marginRight: 8}}
                                                        checked={selected}
                                                    />
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={option.cognome + " " + option.nome}
                                                            secondary={`Compagno indicato: ${option.cognomeCompagnoCamera?.toUpperCase() || '-'} ${option.nomeCompagnoCamera?.toUpperCase() || ''}`}
                                                        >

                                                        </ListItemText>
                                                    </ListItem>

                                                </li>
                                            )}
                                        />}
                                    </Stack>
                                </Stack>
                                : <Typography>
                                    Match esatto con <strong>{exactMatch.nome} {exactMatch.cognome}</strong>
                                </Typography>
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleToggleDialog}>Chiudi</Button>
                    <CustomConfirmDialog
                        openConfirmDialog={openConfirmDialog}
                        handleToggleConfirmDialog={handleToggleConfirmDialog}
                        onConfirm={exactMatch ? handleRemoveRoomate : handleAssignRoomate}
                        variant={'submit'}
                        confirmLabel={exactMatch ? 'DISACCOPPIA' : 'ASSEGNA'}
                        dialogTitle={exactMatch ? 'ELIMINA MATCH' : 'CONFERMA MATCH'}
                        dialogContentText={exactMatch ? 'Elimina il match fra i due invitati' : 'ENTRAMBI i partecipanti coinvolti risulteranno in camera insieme'}
                        startIcon={exactMatch ? <GroupRemove/> : <GroupAdd/>}
                    />
                </DialogActions>
                {!!errorMessage &&
                    <Alert elevation={6} variant={"filled"}
                           severity={'error'}
                           sx={{width: '100%'}}>
                        {errorMessage}
                    </Alert>}
            </Dialog>
        </>
    );
}

export default MatchCompagnoCamera