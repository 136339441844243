import React, {useState} from "react";
import axios from "axios";
import UserService from "../../services/user.service";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {BusAlert, Groups, Hotel, Link, MarkEmailUnread, QrCode2, Rsvp, Send} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const ResendEmailMenu = ({userId, archived, setMessage}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, emailType: null});

    const handleToggleConfirmDialog = (emailType) => {
        console.log("emailType:",emailType)
        setOpenConfirmDialog({
            ...openConfirmDialog,
            open: !openConfirmDialog.open,
            emailType: emailType
        })
        handleCloseUserMenu()
    }

    const handleConfirm = async () => {
        await handleSendEmailToUser()
    }

    const handleSendEmailToUser = () => {
        axios.get(UserService.resendEmailToUserUrl(userId, openConfirmDialog.emailType),
            {
                method: "GET"
            })
            .then(() => {
                setMessage({show: true, text: "Invio effettuato", severity: "success"})
                handleToggleConfirmDialog()
            })
            .catch((err) => {
                setMessage({
                    show: true,
                    text: "Invio non andato a buon fine",
                    severity: "error"
                })
            })
    }


    return <>
        <Button
            id="basic-button"
            color={'accent'}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disabled={archived}
            onClick={handleOpenUserMenu}
            startIcon={<MarkEmailUnread/>}
        >
            Invia comunicazione
        </Button>
        <Menu id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseUserMenu}
              MenuListProps={{
                  'aria-labelledby': 'basic-button',
              }}>
            <MenuItem onClick={() => handleToggleConfirmDialog("SEND_INVITATION")}>
                <ListItemIcon>
                    <Rsvp/>
                </ListItemIcon>
                <ListItemText>{'Invia Invito'}</ListItemText>
                <Send fontSize="small" color={'accent'} sx={{marginLeft: 2}}/>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => handleToggleConfirmDialog("SEND_LOGISTIC")}>
                <ListItemIcon>
                    <BusAlert/>
                </ListItemIcon>
                <ListItemText>{'Invia Info Logistiche'}</ListItemText>
                <Send fontSize="small" color={'accent'} sx={{marginLeft: 2}}/>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => handleToggleConfirmDialog("SEND_HOTEL")}>
                <ListItemIcon>
                    <Hotel/>
                </ListItemIcon>
                <ListItemText>{'Invia Info Hotel'}</ListItemText>
                <Send fontSize="small" color={'accent'} sx={{marginLeft: 2}}/>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => handleToggleConfirmDialog("SEND_QR_CODE")}>
                <ListItemIcon>
                    <QrCode2/>
                </ListItemIcon>
                <ListItemText>{'Invia QR Code'}</ListItemText>
                <Send fontSize="small" color={'accent'} sx={{marginLeft: 2}}/>
            </MenuItem>
            <Divider/>
            <MenuItem onClick={() => handleToggleConfirmDialog("SEND_LINK_STREAMING")}>
                <ListItemIcon>
                    <Link/>
                </ListItemIcon>
                <ListItemText>{'Invia link allo streaming'}</ListItemText>
                <Send fontSize="small" color={'accent'} sx={{marginLeft: 2}}/>
            </MenuItem>
        </Menu>
        <Dialog open={openConfirmDialog.open} onClose={() => handleToggleConfirmDialog(null)}>
            <DialogContent>
                <DialogContentText>
                    Sei sicuro di voler inviare la comunicazione all'utente?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <DialogActions>
                    <Button onClick={handleToggleConfirmDialog}>Annulla</Button>
                    <Button onClick={handleConfirm} variant={'submit'}
                            startIcon={<Send/>}>
                        {'Invia'}
                    </Button>
                </DialogActions>
            </DialogActions>
        </Dialog>
    </>;
}

export default ResendEmailMenu
