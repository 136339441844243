import {API_URL} from "../config";

const usersUrl = ({archived = false, all = false, surname = null}) => {
    if(!!surname)
        return `${API_URL}users?surname=${surname}`
    if(!!all && all === true)
        return `${API_URL}users?all=true`
    return !!archived ? `${API_URL}users${archived ? '?archived=false':''}`
        : `${API_URL}users`
}

const userDataUrl = (id) => {
    return `${API_URL}users/${id||''}`
}

const archiveUserUrl = (id, archive) => {
    return archive ? `${userDataUrl(id)}/archive` : `${userDataUrl(id)}/unarchive`
}

const resendEmailToUserUrl = (id, emailType) => {
    return `${userDataUrl(id)}/resend-invitation${emailType ? ('/'+emailType) : ''}`
}

const UserService = {
    usersUrl,
    userDataUrl,
    archiveUserUrl,
    resendEmailToUserUrl
};

export default UserService;
