import {
    AccessTime,
    AlternateEmail,
    CheckBox,
    CheckBoxOutlineBlank,
    DateRange, FiberManualRecord,
    HorizontalRule,
    Info,
    Link,
    MenuOpen,
    Notes, Numbers,
    QrCodeScanner,
    RadioButtonChecked,
    TextFields,
    Today,
    Tune
} from "@mui/icons-material";
import React from "react";
import _, {camelCase, forEach, replace} from "lodash";

export const fieldTypes = {
    TEXT: {icon: <TextFields color={'disabled'}/>, type: "text", label: "Testo", admin: false},
    NUMBER: {icon: <Numbers color={'disabled'}/>, type: "number", label: "Numero", admin: false},
    EMAIL: {icon: <AlternateEmail color={'disabled'}/>, type: "email", label: "E-mail", admin: false},
    SELECT: {icon: <MenuOpen color={'disabled'}/>, type: "select", label: "Menù a scelta", admin: true},
    DATE: {icon: <DateRange color={'disabled'}/>, type: "date", label: "Data", admin: false},
    DATE_TIME: {icon: <Today color={'disabled'}/>, type: "datetime", label: "Data e Ora", admin: false},
    TIME: {icon: <AccessTime color={'disabled'}/>, type: "time", label: "Ora", admin: false},
    RADIO_GROUP: {icon: <RadioButtonChecked color={'disabled'}/>, type: "radiogroup", label: "Radio group", admin: true},
    CHECK_BOX: {icon: <CheckBox color={'disabled'}/>, type: "checkbox", label: "Checkbox", md: 12, admin: true},
    TEXT_BOX: {icon: <Notes color={'disabled'}/>, type: "textbox", label: "Area di testo", admin: false},
    URL: {icon: <Link color={'disabled'}/>, type: "url", label: "URL", admin: false},
    COLOR: {icon: <FiberManualRecord color={'disabled'}/>, type: "color", label: "Color", admin: true},
    CUSTOM: {icon: <Tune color={'disabled'}/>, type: "custom", label: "Custom", admin: true},
    ACCESS_TYPE: {icon: <QrCodeScanner color={'disabled'}/>, type: "accessType", label: "Tipo di accesso", admin: true},
    NOTE: {icon: <Info color={'disabled'}/>, type: "note", label: "Note", admin: true, onlyUserForm: true},
    DIVIDER: {icon: <HorizontalRule color={'disabled'}/>, type: "divider", label: "Divisore", admin: true, noDataGrid: true},
}

export const getLocaleDateTime = ({ts_seconds}) => {
    return new Date(ts_seconds * 1000).toLocaleString('it-IT')
}

export const icon = <CheckBoxOutlineBlank fontSize="small" />;
export const checkedIcon = <CheckBox fontSize="small" />;

export function getId(fieldName) {
    return fieldName ? camelCase(fieldName.trim()
        .replaceAll(/[^a-zA-Z0-9\s]+/g, "")
        .replaceAll(/\s+/g, "-")) : ""
}

export function parseMultiLineTextBox(value) {
    return value ?
        replace(replace(value.trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
        : "";
}

export function testTime(value) {
    return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
}

export function camelCaseToNatural(str) {
    // Break the camelCase using lodash methods, convert to lower case and then capitalize the first letter of each word
    return _(str)
        // Split the string into words
        .words()
        // Convert words to lower case
        .map(_.lowerCase)
        // Join the words with spaces
        .join(' ')
        // Capitalize the first letter of each word
        .replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
}

export const getColorFromCategoria = (categoria) => {
    switch (categoria) {
        case 'BIANCO':
            return '#FFFFFF'
        case 'AZZURRO':
            return '#00f4ff'
        case 'ROSSO':
            return '#fb3200'
        case 'GIALLO':
            return '#ffea00'
        case 'VIOLA':
            return '#987fbd'
        default:
            return null
    }
}

export const getLabelFromColor = (color) => {
    switch (color) {
        case '#FFFFFF':
            return 'BIANCO'
        case '#00f4ff':
            return 'AZZURRO'
        case '#fb3200':
            return 'ROSSO'
        case '#ffea00':
            return 'GIALLO'
        case '#987fbd':
            return 'VIOLA'
        default:
            return null
    }
}