import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import {Box, Chip, darken, ListItemIcon, TextField} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {InfoOutlined} from "@mui/icons-material";
import ConfirmCheckinDialog from "./ConfirmCheckinDialog";
import {customTheme} from "../../theme/customTheme";
import CustomDialogTitle from "../CustomDialogTitle";
import {getColorFromCategoria} from "../../services/utils";
import useAxios from "axios-hooks";
import {API_URL} from "../../config";

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SearchUserDialog({open, handleClose, users, colorField}) {
    //console.log("users",users)
    document.body.style.backgroundColor = customTheme.palette.secondary.main;

    const [filter, setFilter] = useState('');
    const [infoOpen, setInfoOpen] = useState(false);

    const checkinUser = (e, rowData) => {
        //console.log(rowData.id);
        setFilter('');
        handleClose({hash: rowData.id});
    }

    const showInfo = (e, rowData) => {
        setInfoOpen(rowData)
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            PaperProps={{sx: {borderRadius: '1.1rem 1.1rem 0 0'}}}
            variant={'checkin'}
        >
            {<CustomDialogTitle title={'Cerca partecipante'} handleCloseDialog={handleClose}/>}
            {/*<Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                >
                    <CloseIcon/>
                </IconButton>
                <Button autoFocus color="inherit" onClick={handleClose}>
                    chiudi
                </Button>
            </Toolbar>*/}
            <Box sx={{mx: 2}}>
                <TextField id="outlined-basic" label="Filtra" variant="outlined" sx={{width: '100%'}} onChange={(e) => setFilter(e.target.value.trim())} />
            </Box>
            <ConfirmCheckinDialog colorField={colorField} open={!!infoOpen} handleClose={() => setInfoOpen(null)} user={infoOpen ? infoOpen : null} info/>
            {filter.length < 2 && <Typography sx={{p: 2}} variant={"subtitle2"}>Inserisci almeno due lettere</Typography>}
            <List sx={{pt: 2}} dense>
                {filter.length >= 2 && users
                    .filter((user) =>
                        (user?.nome?.trim().toLowerCase() + ' ' + user?.cognome?.trim().toLowerCase())?.includes(filter.toLowerCase())
                        || (user?.cognome?.trim().toLowerCase() + ' ' + user?.nome?.trim().toLowerCase())?.includes(filter.toLowerCase())
                        || user?.email?.toLowerCase()?.includes(filter.toLowerCase()))
                    .sort((a, b) => a.email?.localeCompare(b.email))
                    .map((val) =>
                    <ListItem
                        key={val.id}
                        dense
                        secondaryAction={
                            (val.checkin === false || !val.checkin) ?
                                <Button variant={"outlined"}
                                        size={'small'}
                                        endIcon={<CheckCircleIcon sx={{ color: 'darkseagreen', fontSize: '2.5rem' }} />}
                                        onClick={(e) => checkinUser(e, val)}>Check-in</Button>
                                : val.checkedInAt ?
                                        <Chip label={`Check-in ${val.checkedInAt ? new Date((val.checkedInAt?._seconds * 1000)).toLocaleTimeString('it-IT') : ''}`}/>
                                        : val.email
                        }
                    >
                        <ListItemIcon>
                            <IconButton onClick={(e) => showInfo(e, val)} >
                                <InfoOutlined fontSize={'small'} color={'primary'}/>
                            </IconButton>
                        </ListItemIcon>
                        <ListItemText
                            primary={val.nome +  ' ' + val.cognome}
                            secondary={val[colorField] ?
                                <Chip size={'small'}
                                      label={val.email}
                                      sx={{
                                          marginTop: 0.5,
                                          fontWeight: 'bold',
                                          /** TODO CHECK */
                                          border: val[colorField] === '#FFFFFF' ? `2px solid ${darken(val[colorField], 0.2)}` : null,
                                          background: val ? (val[colorField] || getColorFromCategoria(val?.categoria) || 'inherit') : 'inherit',
                                          color:  val ? customTheme.palette.getContrastText(val[colorField] || getColorFromCategoria(val?.categoria)) : 'white'
                                      }}
                                /> : val.email}
                        />
                    </ListItem>
                )}
            </List>
        </Dialog>
    );
}
