import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyApNHcNLlVIZENg-RHbUxsAqzggYsPi44M",
    authDomain: "adecco-kickoff-2024.firebaseapp.com",
    projectId: "adecco-kickoff-2024",
    storageBucket: "adecco-kickoff-2024.appspot.com",
    messagingSenderId: "961476869872",
    appId: "1:961476869872:web:fdf6ce7573181f539a0c35"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}