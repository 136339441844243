import {
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {AddCircle, DragHandle, Save} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import {cloneDeep, findIndex} from "lodash";
import CustomTooltip from "../../components/CustomTooltip";
import {DragDropContext, Draggable, Droppable} from '@hello-pangea/dnd';
import CustomDialogTitle from "../../components/CustomDialogTitle";
import IconButton from "@mui/material/IconButton";
import SectionMenu from "./SectionMenu";
import axios from "axios";
import DataStructuresService from "../../services/dataStructures.service";
import FieldForm from "./FieldForm";
import {GlobalContext} from "../../state/global";
import {setDataStructure} from "../../state/global/globalActions";

export const SettingsDialogTypes = {
    NEW_DATA_STRUCTURES: {title: "Aggiungi nuovo form"},
    DATA_STRUCTURES_INFO: {title: "Modifica form:"},
    DATA_STRUCTURES_FIELDS: {
        title: "Aggiungi campi",
        subtitle: "Questi campi saranno utilizzati solo per il backoffice, e non saranno visibili ai partecipanti"
    },
    CHECKIN_PROPS: "CHECKIN_PROPS",
}

const INITIAL_FIELD = {
    id: '0',
    position: 0,
    defaultVisible: true,
    label: "",
    type: "text",
    precompiled: false,
    values: []
}

const INITIAL_SECTION = [
    {
        id: '0',
        label: "",
        position: 0,
        editable: true,
        fields: [{...INITIAL_FIELD}]
    }
]

const HandleFormDialog = ({dialogState, setDialogState}) => {
    console.log("HandleFormDialog > dialogState:", dialogState)
    const [globalState, dispatch] = useContext(GlobalContext)
    const editMode = !!dialogState?.data

    const handleClose = () => {
        setDialogState({
            ...dialogState,
            open: false,
        })
    }

    const [formData, setFormData] = useState({
        label: dialogState?.data?.label || '',
        description: dialogState?.data?.description || '',
        sections: dialogState?.data?.sections || INITIAL_SECTION
    })

    useEffect(() => {
        if(editMode)
            setFormData({
                label: dialogState.data?.label || '',
                description: dialogState.data?.description || '',
                sections: dialogState?.data?.sections || INITIAL_SECTION
            })
    }, [dialogState.data])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({...formData, [event.target.id]: event.target.value});
    };

    const handleSectionInfo = (event, section, index) => {
        const fieldId = event.target.id.split('.')[1]
        const newSections = cloneDeep(formData.sections)
        newSections[index] = {
            ...newSections[index],
            [fieldId]: event.target.value
        }
        console.log("newSections:", newSections)
        setFormData({
            ...formData,
            sections: newSections
        })
    }

    const handleAddSection = () => {
        const newSections = cloneDeep(formData.sections)
        const lastIndex = Object.values(formData.sections).length
        newSections.push({
            id: lastIndex.toString(),
            label: "",
            position: lastIndex,
            editable: true,
            fields: [INITIAL_FIELD]
        })

        setFormData({
            ...formData,
            sections: newSections
        })
    }

    const handleAddField = (section) => {
        console.log("section:",section)
        const _sections = cloneDeep(formData.sections)
        console.log("_sections:",_sections)

        const _sectionIndex = findIndex(_sections, ['id', section])
        console.log("_sectionIndex:",_sectionIndex)

        _sections[_sectionIndex].fields.push({
            ...INITIAL_FIELD,
            id: _sections[_sectionIndex].fields.length,
            position: _sections[_sectionIndex].fields.length
        })
        console.log("handleAddField")
        setFormData({
            ...formData,
            sections: _sections
        })
    }

    console.log("formData:", formData)
    console.log("(Object.values(formData?.sections):", Object.values(formData?.sections))

    const onDragEnd = (event) => {
        console.log("result:", event)
        // dropped outside the list
        if (!event.destination)
            return;
        let tempData = cloneDeep(formData.sections);
        let [source_data] = tempData.splice(event.source.index, 1);
        tempData.splice(event.destination.index, 0, source_data);

        console.log("tempData:", tempData)
        setFormData({
            ...formData,
            sections: tempData.map((section, index) => ({...section, position: index}))
        })
    }

    function onDragEndFields(event, section) {
        if (!event.destination)
            return;
        let _sections = cloneDeep(formData.sections);
        const _sectionIndex = findIndex(_sections, ['id', section])
        let tempData = _sections[_sectionIndex].fields
        let [source_data] = tempData.splice(event.source.index, 1);
        tempData.splice(event.destination.index, 0, source_data);

        tempData = tempData.map((field, index) => ({...field, position: index}))

        _sections[_sectionIndex].fields = tempData

        console.log("tempData:", tempData)
        setFormData({
            ...formData,
            sections: _sections,
        })
    }

    const handleSave = () => {
        axios({
            url: `${DataStructuresService.dataStructuresUrlV2(dialogState?.data?.id || null)}`,
            method: editMode? 'PUT' : 'POST',
            // TODO: migliorare unione dei campi
            data: formData,
        })
            .then((_res) => {
                if (_res) {
                    console.log("_res:",globalState.dataStructures)
                    dispatch(setDataStructure({
                        ..._res.data.data
                    }))
                }
                handleClose()
            })
            .catch((err) => console.log("handleSubmitFields --> err:", err))
    }

    return (
        <Dialog open={dialogState.open} onClose={handleClose} fullScreen
                PaperProps={{variant: 'dialog'}} scroll={'paper'}>
            <CustomDialogTitle title={dialogState.type?.title || ''} handleCloseDialog={handleClose}/>
            <DialogContent>
                <Stack spacing={2} marginX={'20%'}>
                    <TextField variant={'standard'} sx={{marginTop: 1}}
                               placeholder={`Titolo Form`}
                               InputProps={{
                                   sx: {
                                       fontWeight: 'bold',
                                       fontSize: '1.55rem'
                                   }
                               }}
                               id={'label'}
                               label={'Titolo form'}
                               value={formData.label}
                               onChange={handleChange}
                    />
                    <TextField variant={'standard'} size={'small'} sx={{marginTop: 1}}
                               id={'description'}
                               label={'Descrizione'}
                               multiline maxRows={3}
                               value={formData.description}
                               onChange={handleChange}
                    />
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Typography gutterBottom variant={"h6"}>Le sezioni del form</Typography>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <Stack spacing={1}
                                       {...provided.droppableProps}
                                       ref={provided.innerRef}
                                >
                                    {(Object.values(formData?.sections) || []).map((section, index) => (
                                        <Draggable key={index} draggableId={index.toString()}
                                                   index={index}>
                                            {(provided, snapshot) => (
                                                <Card sx={{width: '100%'}}
                                                      variant={'outlined'}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}>
                                                    <CardHeader
                                                        action={<Stack direction={'row-reverse'}>
                                                            <SectionMenu dataStructureId={dialogState?.data?.id } sectionId={section.id} formData={formData} setFormData={setFormData}/>
                                                            <CustomTooltip title={"Riordina" + (index+1)}>
                                                                <IconButton
                                                                    aria-label="settings" {...provided.dragHandleProps}>
                                                                    <DragHandle/>
                                                                </IconButton>
                                                            </CustomTooltip>
                                                        </Stack>}
                                                        title={
                                                            <TextField variant={'standard'}
                                                                       fullWidth
                                                                       InputProps={{
                                                                           sx: {
                                                                               fontWeight: 'bold',
                                                                               fontSize: '1.55rem'
                                                                           }
                                                                       }}
                                                                       id={`${section.id}.label`}
                                                                       label={'Titolo della sezione'}
                                                                       placeholder={`Titolo sezione ${index + 1}`}
                                                                       value={section.label}
                                                                       onChange={(event) => handleSectionInfo(event, section, index)}
                                                            />
                                                        }
                                                        subheader={`Sezione ${index + 1}`}
                                                    />
                                                    <DragDropContext onDragEnd={(event) => onDragEndFields(event, section.id)}>
                                                        <Droppable droppableId="droppableFields">
                                                            {(provided, snapshot) => (
                                                                <Stack spacing={1} p={2}
                                                                       {...provided.droppableProps}
                                                                       ref={provided.innerRef}
                                                                >
                                                                    {(section?.fields || []).map((field, index) => (
                                                                        <Draggable key={index}
                                                                                   draggableId={index.toString()}
                                                                                   index={index}>
                                                                            {(provided, snapshot) => (
                                                                                <Card sx={{width: '100%'}}
                                                                                      variant={'outlined'}
                                                                                      ref={provided.innerRef}
                                                                                      {...provided.draggableProps}
                                                                                >
                                                                                    <FieldForm provided={provided}
                                                                                               section={section.id}
                                                                                               field={field}
                                                                                               index={index}
                                                                                               formData={formData}
                                                                                               setFormData={setFormData}/>
                                                                                </Card>
                                                                            )}
                                                                        </Draggable>))}
                                                                </Stack>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>

                                                    {/*<ListItemText primary={section.label} secondary={section.id}/>
                                                    <CustomTooltip title={"Riordina"}>
                                                        <ListItemSecondaryAction >
                                                            <DragHandle fontSize={'small'}/>
                                                        </ListItemSecondaryAction>
                                                    </CustomTooltip>*/}
                                                    <Stack mb={1} alignItems={'center'}>
                                                        <CustomTooltip title={'Aggiungi domanda'} children={
                                                            <Button aria-label="add"
                                                                    color={'accent'}
                                                                    size={'small'}
                                                                    startIcon={<AddCircle/>}
                                                                    onClick={() => handleAddField(section.id)}
                                                            >
                                                                Aggiungi domanda
                                                            </Button>
                                                        }/>
                                                    </Stack>
                                                </Card>
                                            )}
                                        </Draggable>
                                    ))}
                                </Stack>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Button endIcon={<AddCircle/>} variant={'outlined'} color={'accent'}
                            onClick={handleAddSection}
                    >
                        Aggiungi sezione
                    </Button>
                    {/*globalState.authState.isAdmin &&
                        <Risposte risp={formData.sections || []} handleSetRisposte={handleSetSections}
                                  title={'Aggiungi sezione al form'} optionLabel={'Sezione'}
                                  startIndex={hasSections ? dialogState.data?.sections.length : 0}
                        />*/}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button disabled={!formData.label}
                        onClick={handleSave}
                        variant={'submit'}
                        startIcon={<Save/>}
                >
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default HandleFormDialog