import React from "react";
import _, {filter, find, includes, map, split} from 'lodash'
import {fieldTypes, getColorFromCategoria, getLabelFromColor, parseMultiLineTextBox} from "./utils";
import axios from "axios";
import {API_URL} from "../config";
import {Box, Button, Chip, darken, Stack, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomTooltip from "../components/CustomTooltip";
import {Check, Circle, Close, FiberManualRecord, Link, OpenInNew} from "@mui/icons-material";
import ImgPopUpComponent from "../components/ImgPopUpComponent";
import MatchCompagnoCamera from "../pages/iscritti/MatchCompagnoCamera";
import {customTheme} from "../theme/customTheme";

const columnsUrl = (confId) => {
    return `${API_URL}config/columns/${confId || ''}`
}

const saveColumsToExport = (confId, columns) => {
    return axios.post(`${columnsUrl()}export`, {[confId]: columns},)
};

const saveDefaultVisibilityModel = (formId, columns) => {
    return axios.post(`${columnsUrl()}visibility`, {[formId]: {defaultVisibilityModel: columns}},)
};

const getFieldsFromForm = (form) => {
    let fields = []
    if (form) {
        if (form.sections) {
            form.sections.forEach((section) => {
                fields = fields.concat(map(section.fields, (field) => ({...field, section: section.label})))
            })
            return fields
        } else {
            return form.fields
        }
    }
}

const getColumns = (form, refetch) => {
    const cols = filter(getFieldsFromForm(form), function (o) {
        const field = find(fieldTypes, ['type', o.type])
        return !field?.onlyUserForm && !field?.noDataGrid
    })?.map((field) => {
        function getValue(field, params) {
            const id = (includes(field.id, '.')) ? split(field.id, '.')[1] : field.id
            return field?.getter ? params.row[field.getter]?.[id] : params.row[id]
        }

        let baseField = {
            field: field.id || field,
            headerName: field.headerName || field.label || field.id || (_.startCase(field)),
            flex: 1,
            type: field.type,
            values: field.values
        }

        baseField = field?.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return getValue(field, params)?._seconds ?
                    new Date(getValue(field, params)?._seconds * 1000)
                    : getValue(field, params)
            }
        } : {
            ...baseField, valueGetter: (params) => {
                return getValue(field, params)?._seconds ?
                    new Date(getValue(field, params)?._seconds * 1000)
                    : getValue(field, params)
            }
        }

        const formType = _.find(fieldTypes, ['type', field.type])
        if (!formType?.onlyUserForm) {
            switch (formType) {
                case fieldTypes.DATE:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleDateString('it-IT')
                                    : "");
                        },
                    }

                case fieldTypes.DATE_TIME:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleString('it-IT')
                                    : "");
                        },
                    }

                case fieldTypes.TIME:
                    return {
                        ...baseField,
                        type: 'dateTime', // TODO: capire
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                                : params.value ?
                                    new Date(params.value).toString() !== 'Invalid Date' ?
                                        new Date(params.value).toLocaleTimeString('it-IT')
                                        : params.value
                                    : "");
                        },
                    }

                case fieldTypes.CHECK_BOX:
                    return {
                        ...baseField,
                        flex: 0.6,
                        valueGetter: (params) => {
                            if (getValue(field, params) === true || getValue(field, params) === 'true' || getValue(field, params) === 'si' || getValue(field, params) === 'sì') {
                                return 'si';
                            } else if (getValue(field, params) === false || getValue(field, params) === 'false' || !getValue(field, params) || getValue(field, params) === 'no')
                                return 'no';
                            else return getValue(field, params) || '-'
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'true' || params.value === 'si' || params.value === 'sì') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'false' || params.value === 'no' || !params.value)
                                return 'No';
                            else return 'Non specificato'
                        },
                        renderCell: (params) => (getValue(field, params) === true || getValue(field, params) === 'true' || getValue(field, params) === 'si' || getValue(field, params) === 'sì')
                            ? <Check sx={{color: 'green'}}/>
                            : (getValue(field, params) === false || getValue(field, params) === 'false' || getValue(field, params) === 'no' || getValue(field, params) === '')
                                ? <Close color={'disabled'}/>
                                : '-'
                    }

                case fieldTypes.URL:
                    return {
                        ...baseField,
                        width: 25,
                        flex: 0,
                        renderCell: (params) => params.value ?
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                {/*<CustomTooltip title={"Copia link"}
                                    children={<IconButton size={'small'} color={'primary'}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    navigator.clipboard.writeText(params.value)
                                                    //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))

                                                }}>
                                        <ContentCopy fontSize={'small'}/>
                                    </IconButton>}
                                />*/}
                                <CustomTooltip
                                    title={'Apri link in un\'altra finestra'}
                                    children={
                                        <IconButton size={'small'} color={'primary'}
                                                    href={params.value}
                                                    target={'_blank'}
                                                    onClick={(event) => event.stopPropagation()}>
                                            <OpenInNew fontSize={'small'}/>
                                        </IconButton>}/>
                            </Box> : ""
                    }

                case fieldTypes.ACCESS_TYPE:
                    return {
                        ...baseField,
                        disableExport: true,
                        renderCell: (params) => {
                            if (params.row.partecipazione === 'online')
                                return <CustomTooltip title={"Copia link streaming"} children={
                                    <Button
                                        color={'accent'}
                                        onClick={async (event) => {
                                            event.stopPropagation()
                                            await navigator.clipboard.writeText(`https://teams.microsoft.com/l/meetup-join/19%3ameeting_NWIyZDJmNDUtZTQ4Yy00YmJhLWFlNGQtNmZkZjAyODViNjBm%40thread.v2/0?context=%7B%22Tid%22%3A%22f30ac191-b8b4-45f2-9a9b-e5466cb90c2f%22%2C%22Oid%22%3A%22ba712395-45b8-438d-b3f9-4701713df3d8%22%2C%22IsBroadcastMeeting%22%3Atrue%2C%22role%22%3A%22a%22%7D&btype=a&role=a`)
                                        }}>
                                        <Link/>
                                    </Button>
                                }/>
                            else if (params.row.partecipazione === 'si' || params.row.partecipazione === 'party')
                                return !!params.row.qrCodeUrl ?
                                    <ImgPopUpComponent imgUrl={params.row.qrCodeUrl}/>
                                    : <Chip label={'No QR Code'} size={'small'}/>
                            else if (params.row.partecipazione === 'no')
                                return ''
                        }
                    }

                case fieldTypes.RADIO_GROUP:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        valueGetter: (params) => {
                            const label = find(field.values, ['value', params.value])?.label
                            return label && label !== "Nessuna delle precedenti" ? label : params.value
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì' || params.value === 'partecipo') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no' || params.value === 'nonPartecipo')
                                return 'No';
                            else {
                                const label = find(field.values, ['value', params.value])?.label
                                return label && label !== "Nessuna delle precedenti" ? label : params.value
                            }
                        },
                        renderCell: (params) => {
                            return !!params?.value ? (params?.value === true
                                || params?.value?.toLowerCase() === 'si' || params?.value?.toLowerCase() === 'sì'
                                || params?.value === 'partecipo')
                                ? <Stack direction={'row'} spacing={1}><Check sx={{color: 'green'}}/></Stack>
                                : (params?.value === false || params?.value?.toLowerCase() === 'no' || params?.value === 'nonPartecipo')
                                    ? <Stack direction={'row'} spacing={1}><Close color={'disabled'}/></Stack>
                                    : (find(field.values, ['value', params?.value])?.label || params?.value || '-') : null
                        }
                    }

                case fieldTypes.SELECT:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            const label = find(field.values, ['value', params.value])?.label
                            return label && label !== "Nessuna" ? label : params.value
                        },
                    }

                case fieldTypes.TEXT_BOX:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            return parseMultiLineTextBox(params.value)
                        },
                    }

                case fieldTypes.COLOR:
                    return {
                        ...baseField,
                        valueGetter: (params) => {
                            return getLabelFromColor(params.value)
                        },
                        renderCell: (params) => {
                            return (params?.value || getColorFromCategoria(params?.value)) ?
                                <Stack>
                                    <Chip sx={{
                                        backgroundColor: getColorFromCategoria(params?.value),
                                        fontWeight:'bold',
                                        color: customTheme.palette.getContrastText(getColorFromCategoria(params?.value)),
                                        border: `2px solid ${darken((getColorFromCategoria(params?.value)), 0.2)}`
                                    }}
                                          size={'small'}
                                          label={params?.value}
                                    />
                                </Stack>
                                : '-'//<DoDisturb color={'disabled'}/>
                        }
                    }

                case fieldTypes.CUSTOM:
                    return {
                        ...baseField,
                        flex: 1.5,
                        valueFormatter: (params) => {
                            return (!!params.value)
                                ? `MATCH ESATTO: ${params.value?.cognome} ${params.value?.nome}`
                                : ''
                        },
                        renderCell: (params) => {
                            return (!!params.row.pernottamento && params.row.pernottamento === 'si') ?
                                <MatchCompagnoCamera exactMatch={params.value} user={params.row} refetch={refetch}/>
                                : null
                        }
                    }
                default:
                    return baseField
            }
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumns,
    saveColumsToExport,
    saveDefaultVisibilityModel,
    getFieldsFromForm
};

export default ColumnsService;

/** TODO: sistemare i commonFields */
export const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    }
]