import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";

const CustomConfirmDialog = ({
                           openConfirmDialog,
                           handleToggleConfirmDialog,
                           onConfirm,
                           variant = 'submit',
                           dialogTitle = 'Sei sicuro?',
                           dialogContentText = '',
                           confirmLabel = 'OK',
                           startIcon = null
                       }) => {

    return (<>
            <Button onClick={handleToggleConfirmDialog} variant={'submit'}
                    startIcon={startIcon}
            >
                {confirmLabel}
            </Button>
            <Dialog open={openConfirmDialog} onClose={handleToggleConfirmDialog} maxWidth={'xs'}>
                <DialogTitle>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {dialogContentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DialogActions>
                        <Button onClick={handleToggleConfirmDialog}>Annulla</Button>
                        <Button onClick={onConfirm} variant={variant}
                                startIcon={startIcon}
                        >
                            {confirmLabel}
                        </Button>
                    </DialogActions>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CustomConfirmDialog